import styled, { css } from 'styled-components'
import breakpoints from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

import BannerPJMobile from './assets/banner-pj-mobile.png'
import BannerPJTablet from './assets/banner-pj-tablet.png'
import BannerPJDesktopLG from './assets/banner-pj-desktop-lg.png'
import BannerPJDesktopXL from './assets/banner-pj-desktop-xl.png'
import { Theme } from 'src/types/Theme'

type BannerStyleProps = {
  theme: Theme
}

export const Banner = styled.section<BannerStyleProps>`
  align-items: flex-end;
  background: ${orange.base};
  min-height: 150px;
  
  ${props => props.theme === 'pj' && css`
    background: url(${BannerPJMobile}) no-repeat center;
    background-size: cover;

    @media(min-width: ${breakpoints.md}) {
      background: url(${BannerPJTablet}) no-repeat center;
      background-size: cover;
    }

    @media(min-width: ${breakpoints.lg}) {
      background: url(${BannerPJDesktopLG}) no-repeat center;
      background-size: cover;
    }

    @media(min-width: ${breakpoints.xl}) {
      background: url(${BannerPJDesktopXL}) no-repeat center;
      background-size: cover;
    }
  `}

  @media(min-width: ${breakpoints.lg}) {
    min-height: 225px;   
  }

  @media(min-width: ${breakpoints.xl}) {
    min-height: 300px;   
  }
`
