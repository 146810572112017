import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import { PageProps } from 'gatsby'
import { format, parseISO } from 'date-fns'
import { useLocation } from '@reach/router'
import Layout from 'src/components/Layout'
import BreadCrumb from 'src/components/Breadcrumb'
import BlogArticle from 'src/components/BlogArticle'
import AddThis from 'src/components/AddThis'
import CategoryTab from 'src/components/Tabs/CategoryTab'
import LoadingIndicator from 'src/components/LoadingIndicator'
import CategorySelect from 'src/components/Select/CategorySelect'
import SEO from 'src/components/Seo'

import useScroll from 'src/hooks/useScroll'
import useWidth from 'src/hooks/useWidth'

import isVisible from 'src/utils/isVisible'

import categoriesJSON from 'src/assets/data/categories.json'

import { Category } from 'src/types/Category'

import { Banner } from './style'

type Article = {
  id: string;
  slug: string;
  title: string;
  introduction: string;
  grid_image_url: string;
  category_name: string;
  subcategory_name: string;
  published_at: string;
  updated_at: string;
}

type CategoryProps = {
  pageContext: {
    category: string;
    subcategory: string;
    allArticles: {
      [param: string]: Article[];
    };
  };
} & PageProps

const WIDTH_MD = 768

const CategoryTemplate = ({ pageContext }: CategoryProps) => {
  const scroll = useScroll(300)
  const loadingRef = useRef<SVGSVGElement>(null)
  const [ loadingVisible, setLoadingVisible ] = useState(true)
  const limit = 8
  const [ offset, setOffset ] = useState(limit)
  const [ isMobile, setIsMobile ] = useState(false)
  const windowWidth = useWidth(300)
  const category = categoriesJSON.find((category: Category) => category.slug === pageContext.category)
  const location = useLocation()

  const activeTheme = location.pathname === '/para-o-empreendedor' ? 'pj' : 'pf'

  const quantArticles = Object.keys(pageContext.allArticles).map((slugSubCategory: string) => (
    pageContext.allArticles[slugSubCategory].map((item: Article) => item.id)
  ))

  const breadcrumbPath = [
    {
      text: 'Blog',
      link: '/',
    },
    {
      text: (category as Category).name,
      link: `/${(category as Category).slug}/`,
    },
  ]

  useEffect(() => {
    if (isVisible(loadingRef.current) && (offset <= quantArticles[0].length)) {
      setOffset(offset + limit)
    } else if (offset > quantArticles[0].length) {
      setLoadingVisible(false)
    } else {
      setLoadingVisible(true)
    }
  }, [ scroll ])

  useLayoutEffect(() => {
    setIsMobile(windowWidth <= WIDTH_MD)
  }, [ windowWidth ])

  return (
    <Layout>
      <SEO
        title={`${category?.name} · Blog do Inter`}
        url={`${process.env.BASE_URL}/${category?.slug}/`}
        meta={[
          {
            name: 'description',
            content: category?.description,
          },
          {
            property: 'og:title',
            content: `${category?.name} · Blog do Inter`,
          },
          {
            property: 'og:description',
            content: category?.description,
          },
          {
            property: 'og:locale',
            content: 'pt_BR',
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:url',
            content: `${process.env.BASE_URL}/${category?.slug}`,
          },
          {
            property: 'og:site_name',
            content: 'Blog do Inter',
          },
          {
            property: 'twitter:site',
            content: '@bancointer',
          },
          {
            property: 'twitter:creator',
            content: '@bancointer',
          },
        ]}
      />
      <h1 className='sr-only'>{category?.name} · Blog do Inter</h1>
      <Banner className='d-flex' theme={activeTheme}>
        {activeTheme !== 'pj' &&
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-12 mb-3'>
                <h2 className='fs-24 lh-28 fw-600 text-white'>{category?.name}</h2>
              </div>
            </div>
          </div>
        }
      </Banner>
      <section
        id='compartilhe'
        className='d-flex bg-grayscale--100 py-3 justify-content-center align-items-center h-breadcrumb'
      >
        <div className='container'>
          <div className='row justify-content-center align-items-center'>
            <div className='col-12 col-lg-9'>
              <BreadCrumb path={breadcrumbPath} />
            </div>
            <div className='col-6 col-lg-3 text-right px-0 pr-lg-2'>
              <AddThis />
            </div>
          </div>
        </div>
      </section>
      <section id='artigos-do-blog' className='pt-4 pb-5'>
        <div className='container'>
          {
            !isMobile ? (
              <CategoryTab
                activeTab={pageContext.category}
                activeSubTab={pageContext.subcategory}
                items={categoriesJSON}
                theme={activeTheme}
              />
            ) : (
              <CategorySelect
                activeTab={pageContext.category}
                activeSubTab={pageContext.subcategory}
                items={categoriesJSON}
                theme={activeTheme}
              />
            )
          }
          <div className='row'>
            {/* <div className='col-12 col-lg-8'> */}
            <div className='col-12'>
              <div className='row'>
                {
                  Object.keys(pageContext.allArticles).map((slugSubCategory: string) => (
                    pageContext.allArticles[slugSubCategory].slice(0, offset).map((article: Article) => {
                      return (
                        <div key={article.id} className='col-12 col-md-4 mt-2'>
                          <BlogArticle
                            link={`/${article.slug}/`}
                            image={article.grid_image_url}
                            category={article.category_name}
                            subCategory={article.subcategory_name}
                            title={article.title}
                            date={format(parseISO(article.published_at), 'dd/MM/yyyy')}
                            description={article.introduction}
                            theme={activeTheme}
                          />
                        </div>
                      )
                    })
                  ))
                }
              </div>
              <div className='d-flex justify-content-center'>
                {loadingVisible && <LoadingIndicator ref={loadingRef} />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CategoryTemplate
